<template>
  <div>
   
    <el-card class="intanle">
      <span class="h1">商品カテゴリ管理リスト</span>
    </el-card>
    <el-card class="ft-14">
      <el-row>
        <el-col>
          <!-- <span>商品カテゴリ名称：</span>
                    <el-input v-model="categoryName" placeholder="请输入商品カテゴリ名称" style="width: 19%"></el-input>
                    <span style="float: right; z-index: 5; position: relative">
                        <el-button style="margin-left: 20px;border: none" type="primary" @click="searchTaskList">查询
                        </el-button>
                        <el-button type="info" style="border: none" @click="resetList">リセット</el-button>
                    </span> -->
          <p>
            <span style="float: left">
              <el-button @click="toinsert" type="primary" icon="el-icon-plus" style="background-color: #2a4b9b; border: none">新規</el-button>

              <!-- <el-button type="primary" icon="el-icon-delete" @click="handleDelete1()" style=" background-color: rgb(215 163 16);
border: none;
                  margin-left: 15px;
                ">削除</el-button> -->
            </span>
          </p>
        </el-col>
      </el-row>
    </el-card>
    <!-- リストカードエリア -->
    <el-card>
      <el-table
        max-height="550px"
        @sort-change="sortChange"
        ref="multipleTable"
        v-loading="loading"
        :data="categorylist"
        :header-cell-style="{
          'text-align': 'center',
          color: '#333',
          'font-size': '14px'
        }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        border
        stripe
      >
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <el-table-column label="カテゴリ名" prop="categoryName">
          <template slot-scope="scope">
            <el-button @click="openDetails(scope.row)" type="text" size="small" style="color: #409EFF; text-decoration: underline">{{ scope.row.categoryName }}</el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column label="商户" prop="merchantName"></el-table-column> -->
        <el-table-column label="重み" prop="weight"></el-table-column>
        <el-table-column label="作成日時" prop="createTime" sortable="custom"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click.native.stop="handleupdate(scope.$index, scope.row)" type="text" size="small" class="el-icon-edit" style="color: #1890ff">編集</el-button>
            <el-button @click.native.stop="handleDelete(scope.row)" type="text" size="small" class="el-icon-delete" style="color: #da0909">削除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- ページング -->
      <el-pagination v-if="total > 10" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"> </el-pagination>
      <div style="clear:both;"></div>
    </el-card>
  </div>
</template>

<script>
import categoryService from '@/api/category/categoryService'
export default {
  data() {
    return {
      categoryService: new categoryService(), //インターフェース定義
      categoryName: '', //商品カテゴリ名称
      currentPage: 1, //ページ数
      pageSize: 10, //1ページあたりの件数
      total: 0, //合計数
      categorylist: [], //商品カテゴリ列表数据
      loading: true, //ローディング
      sortBy: '',  //ソート
      multipleSelection: [] //複数選択されたデータ
    }
  },
  created() {
    this.merchantId = window.localStorage.getItem('merchantId')
    this.role = window.localStorage.getItem('currentRole')
    // this.categoryService = new categoryService()
    //リスト取得
    this.GetCategoryList()
  },
  methods: {
    // 複数選択ボックスの値を取得
    handleSelectionChange(val) {
      if (val.length != 0) {
        this.multipleSelection = val
      }
    },
    /* ページサイズ変更イベントのリスナー */
    handleSizeChange(newSize) {
      this.pageSize = newSize
      this.GetCategoryList()
    },
    /* ページ番号変更イベントのリスナー */
    handleCurrentChange(newPage) {
      this.currentPage = newPage
      this.GetCategoryList()
    },
    //リスト取得
    async GetCategoryList() {
      const date = {
        accessToken: localStorage.getItem('authToken'),
        currentRole: localStorage.getItem('currentRole'),
        currentPage: this.currentPage, //ページ数
        pageSize: this.pageSize //1ページあたりの件数
      }
      this.categoryService.list(date).then(res => {
        if (res.data.success == 'true') {
          this.categorylist = res.data.result
          this.loading = false
          this.total = Number(res.data.maxRecords)
          for (var i = 0; i < this.categorylist.length; i++) {
            if (this.categorylist[i].enabled == 1) {
              this.categorylist[i].enabled = true
            } else {
              this.categorylist[i].enabled = false
            }
          }
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    //照会
    async searchTaskList() {
      this.currentPage = 1
      this.GetCategoryList()
    },
    //詳細ページ遷移
    openDetails(row) {
      this.local()
      this.$router.push({
        name: 'GetCategoryDetails',
        query: {
          categoryId: row.categoryId,
          type: 'details'
        }
      })
    },
    //変更ページ移動
    handleupdate(index, row) {
      this.local()
      this.$router.push({
        name: 'GetCategoryDetails',
        query: {
          categoryId: row.categoryId,
          type: 'update',
          currentPage: this.currentPage
        }
      })
    },
    //新規追加ページ遷移
    toinsert() {
      this.local()
      this.$router.push({
        name: 'GetCategoryDetails',
        query: {
          type: 'insert'
        }
      })
    },
    //リセット
    resetList() {
      this.name = ''
      this.GetCategoryList()
    },
    //1行削除
    handleDelete(row) {
      var data = [row.categoryId]
      this.$confirm('この操作はデータを永久に削除します。続行しますか?', '確認', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const date = {
            accessToken: localStorage.getItem('authToken'),
            currentRole: localStorage.getItem('currentRole'),
            categoryId: row.categoryId
          }
          this.categoryService.delete(date).then(res => {
            if (res.data.success == 'true') {
              this.$message({
                type: 'success',
                message: '削除成功!'
              })
              this.GetCategoryList()
            } else {
              this.$message.error(res.data.message)
            }
          })
        })
        .catch(err => {
          this.GetCategoryList()
        })
    },
    //リストのソート
    sortChange(column) {
      if (column.order == 'ascending') {
        if (column.prop == 'createTime') {
          this.sortBy = 'c.createTime ASC'
        }
      } else if (column.order == 'descending') {
        if (column.prop == 'createTime') {
          this.sortBy = 'c.createTime DESC'
        }
      }
      this.GetCategoryList()
    },
    //編集時にフィルター条件をキャッシュに保存
    local() {
      var zph_management_params = {
        currentPage: this.currentPage, //ページ数
        pageSize: this.pageSize //1ページあたりの件数
      }
      localStorage.setItem('zph_management_params', JSON.stringify(zph_management_params))
    }
  }
}
</script>

<style lang="less" scoped>
.cl-hui {
  color: #b2b2b2 !important;
}

.cl-bl {
  color: #606262;
}
</style>
